import { Link } from "gatsby"
import React from 'react';

import styled from 'styled-components';

// import Scroll from 'react-scroll';
// var scroller = Scroll.scroller;

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {  faYoutube,  faTwitter } from '@fortawesome/free-brands-svg-icons'
import { faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons'

const Container = styled.div`
    padding: 80px 60px;
    /* background: radial-gradient(circle, gold 0%, orange 100%); */
    background: rgb(0, 153, 255);
    /* background: #0091f5; */
    justify-content: center;
`

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    max-width: 700px;
    margin: 0 auto;
`
const Row = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 60px;
    justify-content: center;

    @media (max-width: 700px) {
        grid-template-columns: repeat(auto-fill, minmax(200px,1fr));
        grid-gap: 20px;
    }
`

const Column = styled.div`
    display: flex;
    flex-direction: column;    
    text-align: center;
    max-width: 200px;
    margin: 0 auto;
`

const Title = styled.p`
    color: #ffffff;
    max-width: 200px;
    text-align: center;
    font-size: 20px;
    font-weight: 550;
`

const Link1 = styled(Link)`
    color: #fbfbfb;
    margin-bottom: 20px;
    font-size: 18px;
    text-decoration: none;
    max-width: 200px;

    &:hover {
        /* color: ${props => props.theme.lightBlu};
        transition: 200ms ease-in;
        text-decoration: none; */
        color: #ffffff;
        text-decoration: none;
        text-shadow: 0 0 25px #ffffff;
    }
`

const Link2 = styled.a`
    color: #fbfbfb;
    margin-bottom: 20px;
    font-size: 18px;
    text-decoration: none;
    max-width: 200px;

    &:hover {
        color: #ffffff;
        text-decoration: none;
        text-shadow: 0 0 25px #ffffff;
        /* color: ${props => props.theme.lightBlu};
        transition: 200ms ease-in;
        text-decoration: none; */
    }
`

const StyledIcon = styled(FontAwesomeIcon)`
    font-size: 18px;
    margin-right: 8px;
`;

const StyledIcon2 = styled(FontAwesomeIcon)`
    font-size: 18px;
    margin-left: 8px;
`;

const Footer = () => {
    return (
        <Container>
            <Wrapper>
                <Row>
                    <Column>
                        <Title>About</Title>
                        <Link1 to='/AboutMe'>About Me</Link1>
                        {/* <Link1 to='/AboutGabo'>The Gabo App</Link1> */}
                        <Link1 to='/AboutDuoLivre'>DuoLivre, LLC</Link1>
                    </Column>
                    <Column>
                        <Title>Social</Title>
                        <Link2 href="https://www.youtube.com/channel/UC9rAyFuwV_agGEO1qO2tNbA/videos" target="_blank" rel="noopener noreferrer"><StyledIcon icon={faYoutube}/>YouTube<StyledIcon2 icon={faExternalLinkAlt}/></Link2>
                        <Link2 href="https://twitter.com/xtina_digital" target="_blank" rel="noopener noreferrer"><StyledIcon icon={faTwitter}/>Twitter<StyledIcon2 icon={faExternalLinkAlt}/></Link2>
                    </Column>
                </Row>
            </Wrapper>
        </Container>
    )
}

export default Footer

// Color of undraw SVGs: #0f8fe5
// Hair color: #9f6310
// Skin color: #ffd8b8
// Dark blue undraw hex: 3F3D56
// Orangey-yellow hex: ffa500

//GABO eyes: 0de0c9
//GABO hair: 201302
//GABO skin: c19e6d

// export const darkCerulean = "#384247";

// export const darkCerulean75 = "#384247";

// export const lightBlu = "#037bfe";

export const darkCerulean = "#0f2f4f";

export const darkCerulean75 = "rgba(9, 83, 133,.75)";

export const lightBlu = "rgb(0, 153, 255)";

export const naranja = "#ffa500";
import { Link } from "gatsby"
import PropTypes from "prop-types"
import React, {useState} from "react"
import 'bootstrap/dist/css/bootstrap.min.css';
import './header.css';
import {FaBars, FaTimes} from 'react-icons/fa'
import { GiSunflower } from "@react-icons/all-files/gi/GiSunflower";
import { NavMenu, MobileIcon } from "./Navbar/NavbarElements";

const Header = ({ siteTitle }) => {
  const [click, setClick] = useState(false);
  const handleClick = () => setClick(!click)
  return (  
    <div className="navbar color-nav" expand="md" fixed="top">
      <Link to="/">
        <div className="navbar navbar-brand" id="xtinapark">
          <GiSunflower/>{siteTitle}
        </div>
      </Link>
      {/* <div className="link-container"> */}
      <NavMenu click={click}>
        <Link to="/Contact">
          <div className="item-link">
            CONTACT
          </div>
        </Link>
      </NavMenu>
      {/* </div> */}
      <MobileIcon onClick={handleClick}>
        {click? <FaTimes/> : <FaBars/>}
      </MobileIcon>
    </div>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header

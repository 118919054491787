import React from 'react';
import {Title, Subtitle, Text, Subtext, Image, Book, CaptionDiv, Container} from './styles';

function content({children, ...styles}) {
    return (
        <div {...styles}>
            {children}
        </div>
    );
}

content.Title = function ContentTitle({children, ...styles}) {
    return <Title {...styles}>{children}</Title>
}

content.Subtitle = function ContentSubtitle({children, ...styles}) {
    return <Subtitle {...styles}>{children}</Subtitle>
}

content.Text = function ContentText({children, ...styles}) {
    return <Text {...styles}>{children}</Text>
}

content.Subtext = function ContentSubtext({children, ...styles}) {
    return <Subtext {...styles}>{children}</Subtext>
}

content.Image = function ContentImage({children, ...styles}) {
    return <Image {...styles}>{children}</Image>
}

content.Book = function ContentBook({children, ...styles}) {
    return <Book {...styles}>{children}</Book>
}

content.CaptionDiv = function ContentCaptionDiv({children, ...styles}) {
    return <CaptionDiv {...styles}>{children}</CaptionDiv>
}

content.Container = function ContentContainer({children, ...styles}) {
    return <Container {...styles}>{children}</Container>
}

export default content;
/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"

import styled from 'styled-components';
import {ThemeProvider} from 'styled-components';

import * as theme from '../theme';

import Header from "./header"
import "./layout.css"
// import Navbar from "./Navbar"
import Footer from "./Footer"

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  const Containz = styled.div`
    display: flex;
    flex-direction: column;
    margin: 0;
    padding: 0;
  `
  const DIVM = styled.div`
    padding: 0rem 0rem;
    min-height: 60vh;
    position: relative;
  ` 
  const DIVF = styled.div`
    margin-top: auto;
    position: relative;
  `

// position: relative;

  return (
    <ThemeProvider theme = {theme}>
      <Containz>
        <Header siteTitle={data.site.siteMetadata?.title || `Title`} />
        {/* <Navbar/> */}
        <DIVM><main>{children}</main></DIVM>
        <DIVF><Footer/></DIVF>
      </Containz>
    </ThemeProvider>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout

import styled from 'styled-components';

export const Title = styled.h1`
    color: ${props => props.theme.darkCerulean};
    text-align: center;
    font-weight: 400;
    font-size: 45px;
    margin-top: 0;
    margin-bottom: .5rem;

    ${props => {
        switch (props.align){
            case "left":
                return 'text-align: left;';
            }
            return null;
        }
    }
    ${props => {
        switch (props.float){
            case "left":
                return 'float: left; padding-right: 15px;';
            }
            return null;
        }
    }
    ${props => {
        switch (props.color){
            case "white":
                return 'color: whitesmoke;';
            case "black":
                return 'color: #1C2833;';
            case "grey":
                return 'color: darkgrey;';
            }
            return null;
        }
    }

    @media only screen and (max-width: 1200px) {
        font-size: 40px;
    }

    @media only screen and (max-width: 600px) {
        font-size: 35px;
    }

    @media only screen and (max-width: 500px) {
        font-size: 25px;
    }


    @media only screen and (max-width: 375px) {
        font-size: x-large;
    }

    @media only screen and (max-width: 300px) {
        font-size: large;
    }
    
`

export const Subtitle = styled.h1`
    color: ${props => props.theme.lightBlu};
    font-weight: 400;
    font-size: 35px;
    margin-top: 1rem;
    margin-bottom: .5rem;

    ${props => {
        switch (props.color){
            case "white":
                return 'color: whitesmoke;';
            case "darkCerulean":
                return 'color: #0f2f4f;';
            case "black":
                return 'color: #1C2833;';
            }
            return null;
        }
    }
    ${props => {
        switch (props.align){
            case "center":
                return 'text-align: center;';
            }
            return null;
        }
    }
    

    @media only screen and (max-width: 1200px) {
        font-size: 30px;
    }

    @media only screen and (max-width: 600px) {
        font-size: 25px;
    }

    @media only screen and (max-width: 500px) {
        font-size: 22px;
    }


    @media only screen and (max-width: 375px) {
        font-size: 20px;
    }

    @media only screen and (max-width: 300px) {
        font-size: 10;
    }
    
`

export const Text = styled.p`
    margin: 0px;
    padding: 0px;
    text-align: left;
    font-size: x-large;
    color: #1C2833 ;

    @media only screen and (max-width: 500px) {
        font-size: large;
    }

    @media only screen and (max-width: 375px) {
        font-size: 15px;
    }

    ${props => {
        switch (props.color){
            case "white":
                return 'color: whitesmoke;';
            case "lightgrey":
                return 'color: #ebebeb';
            case "darkCerulean":
                return 'color: rgb(9, 83, 133)';
            }
            return null;
        }
    }

    ${props => {
        switch (props.align){
            case "center":
                return 'text-align: center;';
            }
            return null;
        }
    }
`

export const Subtext = styled.p`
    font-size: medium;
    color: #1C2833 ;

    @media only screen and (max-width: 450px) {
        font-size: small;
    }

    @media only screen and (max-width: 300px) {
        font-size: x-small;
    }
`

export const Image = styled.img`
    padding: 2.5px;
    /* border-radius: 25px; */
    max-width: 500px;
    height: auto;

    @media only screen and (max-width: 700px) {
        max-width: 100%;
    }

    ${props => {
            switch (props.max){
                case "100":
                    return 'max-width: 100%;';
            }
            return null;
        }
    }

    ${props => {
            switch (props.disappear){
                case "medium":
                    return '@media (max-width: 1640px) {display: none;}';
            }
            return null;
        }
    }
`

export const Book = styled.img`
    padding: 2.5px;
    border-style: solid;
    border-color: black;
    /* border-radius: 25px; */

    max-width: 100%;
    height: auto;

    &:hover {
        box-shadow: 0 0 25px #91ff00;
    }
`

export const CaptionDiv = styled.div`
    max-width: 500px;
    height: auto;
    color: #1C2833 ;

    @media only screen and (max-width: 500px) {
        max-width: 100%;
    }
`

export const Container = styled.div`
    align-content: center;
    padding: 0rem 20rem;

    @media only screen and (max-width: 1500px) {
        padding: 0rem 15rem;
    }

    @media only screen and (max-width: 1300px) {
        padding: 0rem 8rem;
    }

    @media only screen and (max-width: 1000px) {
        padding: 0rem 6rem;
    }
    @media only screen and (max-width: 700px) {
        padding: 0rem 5rem;
    }
    @media only screen and (max-width: 600px) {
        padding: 0rem 2rem;
    }
    @media only screen and (max-width: 300px) {
        padding: 0rem 1rem;
    }
`

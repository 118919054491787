import styled from 'styled-components';

export const MobileIcon = styled.div`
    display: none;
    @media screen and (max-width: 600px) {
        color: #ffffff;
        display: block;
        position: absolute;
        right: 1rem;
        transform: translate()(-100%, 60%);
        font-size: 1.5rem;
        cursor: pointer;
    }
    @media screen and (max-width: 375px) {
        font-size: 1rem;
    }
`

export const NavMenu = styled.ul`
    display: flex;
    align-items: center;
    list-style: none;
    text-align: center;

    padding: 0;
    margin: 0;
    justify-content: center;
    height: 5rem;

    @media screen and (max-width: 600px) {
        background-color: orange;
        z-index: 2;
        display: flex;
        flex-direction: column;
        width: 100vw;
        height: 100px;
        position: absolute;
        margin: 0px;
        justify-content: center;
        top: ${({click}) => (click? "100%" : "-1000px")};
        opacity: 1;
        transition: all 0.2s ease;
    }
`